import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useToast } from '@opengovsg/design-system-react'
import simplur from 'simplur'

import {
  GetNextAvailabilityRes,
  GetPublicBookingRes,
  GetPublicEventRes,
} from '~shared/dto'
import { EventAuthType, EventStatus } from '~shared/types'
import { isValidNanoId, nanoid } from '~shared/utils'

import { isNestError } from '~lib/api'
import { BrowserEvent } from '~constants/events'
import { useNavigateOnEvent } from '~hooks/useNavigateOnEvent'
import { secondsToMinutes } from '~utils/date'
import {
  BookingNotFound,
  EventClosed,
  EventNotFound,
} from '~components/ErrorPages'
import { FourOhFour } from '~components/FourOhFour'
import { LoadingState } from '~components/LoadingState'

import { useEventIdParam } from '~features/events/hooks/useEventIdParam'

import {
  formStateSelector,
  identitySelector,
  previousBookingSelector,
  setFormResponsesSelector,
  setFormStateSelector,
  setIdentitySelector,
  setPreviousBookingSelector,
  useFormStore,
} from '../hooks/useFormStore'
import { useGetNextAvailability } from '../hooks/useGetNextAvailability'
import { useGetPublicBooking } from '../hooks/usePublicBooking'
import { useGetPublicEvent } from '../hooks/usePublicEvent'
import { FormState } from '../types/formState'

import { RescheduleSummaryPage } from './steps/RescheduleSummaryPage'
import { EventFormLayout } from './EventFormLayout'
import {
  CancelConfirmationPage,
  CancelPage,
  ConfirmationPage,
  EventSummaryPage,
  FormFieldsPage,
  PickDateTimePage,
} from './steps'

// Ensure email field ID is stable across mount and unmount
const emailFieldId = nanoid()

const renderFormState = ({
  event,
  availability,
  formState,
  previousBooking,
}: {
  event: GetPublicEventRes
  availability: GetNextAvailabilityRes
  previousBooking: GetPublicBookingRes | null
  formState: FormState
}): JSX.Element => {
  switch (formState) {
    case FormState.ViewSummary:
      return previousBooking ? (
        <RescheduleSummaryPage
          event={event}
          previousBooking={previousBooking}
        />
      ) : (
        <EventSummaryPage event={event} />
      )
    case FormState.PickDate:
    case FormState.PickTime:
      return <PickDateTimePage event={event} availability={availability} />
    case FormState.FormFields:
      return <FormFieldsPage event={event} emailFieldId={emailFieldId} />
    case FormState.Submitted:
      return <ConfirmationPage event={event} />
    case FormState.Cancelling:
      return <CancelPage event={event} />
    case FormState.CancellationSubmitted:
      return <CancelConfirmationPage event={event} />
    case FormState.RedirectFromSgidCallback:
      return <LoadingState w="full" flex={1} />
    default:
      return <FourOhFour />
  }
}

const shouldShowTitleBar = (formState: FormState) =>
  formState === FormState.PickDate ||
  formState === FormState.PickTime ||
  formState === FormState.FormFields

enum IdentityCheckStatus {
  Checking,
  CompleteWithSuccess,
  CompleteWithFailure,
}

export const EventFormPage = () => {
  // Fetch the event object
  const { eventId } = useEventIdParam()
  const isEventIdValid = useMemo(() => isValidNanoId(eventId), [eventId])
  const {
    data: event,
    isLoading: isEventLoading,
    isError: isEventError,
  } = useGetPublicEvent({
    eventId,
    // Security measure to ensure user cannot make arbitrary GET requests
    // by editing the URL, e.g. by going to /..%252fauth%252fwhoami
    enabled: isEventIdValid,
    retry: (failureCount, error) => {
      // Prevent retries if failureCount >= 3 OR status code is known to be 404
      return failureCount < 3 && (!isNestError(error) || error.status !== 404)
    },
  })

  // Fetch the event availability
  const {
    data: availability,
    isLoading: isAvailabilityLoading,
    isError: isAvailabilityError,
  } = useGetNextAvailability({
    // Falsy empty string will be ignored by the query
    scheduleId: event?.schedules[0].id ?? '',
    enabled: event !== undefined,
    retry: (failureCount, error) => {
      // Prevent retries if failureCount >= 3 OR status code is known to be 404
      return failureCount < 3 && (!isNestError(error) || error.status !== 404)
    },
  })

  // Fetch the booking object
  const [searchParams] = useSearchParams()
  const bookingId = useMemo(() => searchParams.get('bookingId'), [searchParams])
  const isBookingIdValid = useMemo(
    () => !!bookingId && isValidNanoId(bookingId),
    [bookingId],
  )

  const {
    data: booking,
    isLoading: isBookingLoading,
    isError: isGetBookingError,
  } = useGetPublicBooking({
    bookingId: bookingId ?? undefined,
    // Security measure to ensure user cannot make arbitrary GET requests
    // by editing the URL, e.g. ?bookingId=../auth/whoami
    enabled: isBookingIdValid,
    retry: (failureCount, error) => {
      // Prevent retries if failureCount >= 3 OR status code is known to be 404
      return failureCount < 3 && (!isNestError(error) || error.status !== 404)
    },
  })

  // Set the HTML title so long the event is defined.
  // (It is defaulted as CalSG, see index.html)
  useEffect(() => {
    if (event?.title) document.title = event.title
  }, [event])

  // When query to fetch event is disabled because event ID is invalid,
  // query will initialise with `isLoading === true`. So this case has
  // to come before the isLoading case.
  if (isEventError || !isEventIdValid || isAvailabilityError) {
    return <EventNotFound />
  }
  if (!!bookingId && (isGetBookingError || !isBookingIdValid)) {
    return <BookingNotFound />
  }
  // We wait for event, availability, and booking to load
  if (
    isEventLoading ||
    (!!bookingId && isBookingLoading) ||
    isAvailabilityLoading
  ) {
    return <LoadingState height="100vh" />
  }

  // Event is closed and this is not a reschedule
  if (!bookingId && event.eventStatus === EventStatus.Closed) {
    return (
      <EventClosed minH="100vh" closedEventMessage={event.closedEventMessage} />
    )
  }

  if (
    booking &&
    (booking.eventId !== eventId ||
      booking.scheduleId !== event.schedules[0].id)
  ) {
    return <BookingNotFound />
  }

  return (
    <EventFormPageInnerEffects
      event={event}
      booking={booking ?? null}
      availability={availability}
    />
  )
}

/**
 * A lot of useEffect on this page. Separating them into an inner component
 * for convenience to avoid interaction with loading states.
 */
const EventFormPageInnerEffects = ({
  event,
  booking,
  availability,
}: {
  event: GetPublicEventRes
  booking: GetPublicBookingRes | null
  availability: GetNextAvailabilityRes
}) => {
  const formState = useFormStore(formStateSelector)
  const previousBooking = useFormStore(previousBookingSelector)
  const setFormState = useFormStore(setFormStateSelector)
  const setPreviousBooking = useFormStore(setPreviousBookingSelector)
  const setFormResponses = useFormStore(setFormResponsesSelector)
  const identity = useFormStore(identitySelector)
  const setIdentity = useFormStore(setIdentitySelector)

  const [searchParams] = useSearchParams()
  const toast = useToast()
  // We use this flag to show the toast only once
  const [identityCheckStatus, setIdentityCheckStatus] =
    useState<IdentityCheckStatus>(IdentityCheckStatus.Checking)
  const [loginExpiryPromptShown, setLoginExpiryPromptShown] = useState(false)
  const navigate = useNavigate()
  // Effect 1
  // This effect automatically selects the previous booking's slot when the booking loads
  useEffect(() => {
    if (booking) {
      setPreviousBooking(booking)
      const action = searchParams.get('action')
      if (action === 'cancel') {
        setFormState(FormState.Cancelling)
      }
    }
  }, [booking, searchParams, setFormState, setPreviousBooking])

  // Effect 2
  // This effect checks if we consider the sgID login valid based on the nricFin field in
  // the response bodies of event and booking
  useEffect(() => {
    if (!event || !event.auth || !event.auth.type) {
      return
    }

    if (event.auth.type !== EventAuthType.SgidWithNric) {
      setIdentityCheckStatus(IdentityCheckStatus.CompleteWithSuccess)
      return
    }

    // Event requires sgID authentication
    // We consider 5 cases,
    // 1. event.nric = null, booking.nric = null - Not logged in
    // 2. event.nric = null, booking.nric != null - Not supposed to happen, request relogin in case
    // 3. event.nric != null, booking.nric = null - Logged in, wrong user
    // 4. event.nric != booking.nric - Weird error, but we reject the identity just in case
    // 5. event.nric != null, booking.nric != null, event.nric == booking.nric - Logged in, correct user

    const eventIdentity: string | null = event.auth.sessionNricFin ?? null

    if (eventIdentity === null) {
      // Case 1 & 2, not logged in
      setIdentityCheckStatus(IdentityCheckStatus.CompleteWithSuccess)
      return
    }

    // We are making a new booking, nric on event is a sufficient condition
    if (!booking) {
      setIdentity(eventIdentity)
      setIdentityCheckStatus(IdentityCheckStatus.CompleteWithSuccess)
      return
    }

    // Case 3 - event.identity is defined, but booking.identity is undefined.
    // This occurs when the identity provided does not match the
    // identity of the booking.
    if (booking.sessionNricFin === undefined) {
      setIdentityCheckStatus(IdentityCheckStatus.CompleteWithFailure)
      return
    }

    // Case 4
    if (booking.sessionNricFin !== eventIdentity) {
      // Mismatched identities, something went wrong somewhere, require the user
      // to login again.
      setIdentityCheckStatus(IdentityCheckStatus.CompleteWithFailure)
      return
    }

    // Case 5 - booking.identity == event.identity
    setIdentity(eventIdentity)
    setIdentityCheckStatus(IdentityCheckStatus.CompleteWithSuccess)
    const { responses, email } = booking
    if (responses && email) {
      setFormResponses({ ...responses, [emailFieldId]: email })
    }
  }, [booking, event, setFormResponses, setIdentity])

  // Effect 3
  // This effect handles showing a toast when the user's sgID login is invalid.
  // Note that this is separated out to ensure that the toast only shows once.
  useEffect(() => {
    if (identityCheckStatus === IdentityCheckStatus.CompleteWithFailure) {
      toast({
        description:
          'Sorry, this booking can only be changed using the Singpass that booked it.',
        status: 'error',
      })
    }
    // We disable the toast dependency because every time it is called, toast itself changes
    // and causes this effect to run and show again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityCheckStatus])

  // Effect 4
  // This effect is used to advance the user to the pick date page after sgID login
  useEffect(() => {
    const action = searchParams.get('action')
    if (
      formState === FormState.RedirectFromSgidCallback &&
      identityCheckStatus !== IdentityCheckStatus.Checking
    ) {
      if (action === 'cancel') {
        // This is handled by effect 1
        return
      } else if (identity && !event?.redirectToBookingId) {
        // We only transition to PickDate if the user has successfully logged in
        // and does not need to be redirected away.
        setFormState(FormState.PickDate)
      } else {
        setFormState(FormState.ViewSummary)
      }
    }
  }, [
    event?.redirectToBookingId,
    formState,
    identity,
    identityCheckStatus,
    searchParams,
    setFormState,
  ])

  const showSessionExpiryToast = useCallback(
    (numMinutesLeft: number) => {
      if (loginExpiryPromptShown) {
        return
      }

      if (numMinutesLeft < 0) {
        toast({
          description:
            'Your Singpass login has expired. Please login with Singpass again.',
          status: 'error',
        })
      } else {
        // The toast does not dismiss itself automatically because we want the toast to remain on screen
        // until the user acknowledges it.
        toast({
          description:
            simplur`Your Singpass login expires in ${numMinutesLeft} minute[|s]. ` +
            'Please complete your booking soon or login with Singpass again to extend your session.',
          status: 'info',
          duration: null,
        })
      }

      setLoginExpiryPromptShown(true)
    },
    [loginExpiryPromptShown, toast],
  )

  // Effect 5
  // This effect handles the showing of the sgID login session expiry info toast
  // This effect depends on event, which is refetched every time the tab is focused
  useEffect(() => {
    if (event?.auth.type !== EventAuthType.SgidWithNric) {
      return
    }

    if (event.auth.sessionExpiresAt === undefined) {
      return
    }

    if (identityCheckStatus !== IdentityCheckStatus.CompleteWithSuccess) {
      return
    }

    // This timestamp is tracked in Unix seconds
    const nowUnixSecs = Math.floor(Date.now() / 1000)
    const timeToExpiry = event.auth.sessionExpiresAt - nowUnixSecs

    // 10 mins
    const sessionExpiryWarningLeadTime = 10 * 60

    // If its more than lead time left, we delay showing the toast
    if (timeToExpiry > sessionExpiryWarningLeadTime) {
      let hasTimeoutRun = false
      const timeoutId = setTimeout(() => {
        // We show when it is 10 minutes away
        showSessionExpiryToast(10)
        hasTimeoutRun = true
      }, (timeToExpiry - sessionExpiryWarningLeadTime) * 1000)

      return () => {
        if (!hasTimeoutRun) {
          clearTimeout(timeoutId)
        }
      }
    } else {
      // Otherwise, we show the toast immediately
      showSessionExpiryToast(secondsToMinutes(timeToExpiry))
    }
  }, [event, identityCheckStatus, showSessionExpiryToast])

  // Effect 6
  // This is used to perform a redirect if the GET event endpoint returns
  // a bookingId to redirect to.
  useEffect(() => {
    // If a booking is already specified, we don't redirect away
    if (!event || booking) {
      return
    }

    if (event.redirectToBookingId) {
      navigate(`/${event.id}?bookingId=${event.redirectToBookingId}`)
    }
  }, [event, booking, navigate])

  useNavigateOnEvent({
    event: BrowserEvent.TOO_MANY_REQUESTS,
    path: '/too-many-requests',
  })

  // Whenever state changes, scroll back to the top
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [formState])

  return (
    <EventFormLayout event={event} showTitleBar={shouldShowTitleBar(formState)}>
      {renderFormState({ formState, event, previousBooking, availability })}
    </EventFormLayout>
  )
}
