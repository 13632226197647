import { useMemo, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormControl, Heading, VStack } from '@chakra-ui/react'
import { TurnstileInstance } from '@marsidev/react-turnstile'
import {
  Button,
  FormErrorMessage,
  useToast,
} from '@opengovsg/design-system-react'
import { useQueryClient } from '@tanstack/react-query'

import {
  FormResponseReq,
  GetFormFieldRes,
  GetPublicEventRes,
} from '~shared/dto'
import { FormFieldType } from '~shared/types'

import { getNetworkErrorMessage, isNestError } from '~lib/api'
import { publicQueryKeys } from '~constants/queryKeys'

import {
  isSlotGoneSelector,
  previousBookingSelector,
  setFormSubmittedSelector,
  setIsSlotGoneSelector,
  slotSelector,
  useFormStore,
} from '~features/form/hooks/useFormStore'
import { useSubmitForm } from '~features/form/hooks/useSubmitForm'

import { DropdownFieldView } from '../fields/DropdownFieldView'
import { EmailField } from '../fields/EmailField'
import { MobileFieldView } from '../fields/MobileFieldView'
import { NricFieldView } from '../fields/NricFieldView'
import { RadioFieldView } from '../fields/RadioFieldView'
import { TextFieldView } from '../fields/TextFieldView'

import {
  TURNSTILE_DEFAULT_ERROR_MESSAGE,
  TURNSTILE_TOKEN_FIELD_NAME,
  TurnstileField,
} from './TurnstileField'

const TURNSTILE_MAX_TIMEOUT_SECONDS = 5

const delay = (milliseconds: number) =>
  new Promise((resolve) => setTimeout(resolve, milliseconds))

export interface FormFieldsProps {
  event: GetPublicEventRes
  emailFieldId: string
}

const generateFormField = (fieldDto: GetFormFieldRes) => {
  switch (fieldDto.fieldType) {
    case FormFieldType.Radio:
      return <RadioFieldView {...fieldDto} key={fieldDto.id} />
    case FormFieldType.Mobile:
      return <MobileFieldView {...fieldDto} key={fieldDto.id} />
    case FormFieldType.Nric:
      return <NricFieldView {...fieldDto} key={fieldDto.id} />
    case FormFieldType.Dropdown:
      return <DropdownFieldView {...fieldDto} key={fieldDto.id} />
    default:
      return <TextFieldView {...fieldDto} key={fieldDto.id} />
  }
}

export const FormFields = ({
  event,
  emailFieldId,
}: FormFieldsProps): JSX.Element => {
  const { formFields } = event
  const scheduleId = useMemo(() => event.schedules[0].id, [event])
  const slot = useFormStore(slotSelector)
  const previousBooking = useFormStore(previousBookingSelector)
  const setFormSubmitted = useFormStore(setFormSubmittedSelector)
  const isSlotGone = useFormStore(isSlotGoneSelector)
  const setIsSlotGone = useFormStore(setIsSlotGoneSelector)
  const submitFormMutation = useSubmitForm({ eventId: event.id, scheduleId })
  const toast = useToast()
  const queryClient = useQueryClient()
  const formMethods = useFormContext<Record<string, string>>()
  const turnstileRef = useRef<TurnstileInstance>(null)
  const {
    formState: { isSubmitting },
  } = formMethods
  const onSubmit = formMethods.handleSubmit(async (data) => {
    if (!slot) {
      toast({
        status: 'error',
        description: 'Please select a timeslot and try again.',
      })
      return
    }
    let finalTurnstileToken = data[TURNSTILE_TOKEN_FIELD_NAME]
      ? data[TURNSTILE_TOKEN_FIELD_NAME]
      : undefined
    // Turnstile is invalid. This can happen if:
    // 1. Challenge hasn't loaded yet
    // 2. Challenge has just expired and is rerunning the challenge. In this case,
    // wait for it to load so that it's invisible to users as far as possible
    if (!event.shouldBypassTurnstile && !finalTurnstileToken) {
      try {
        // Library has a bug where it errors when calling getResponsePromise right after
        // a token expires and before the next token loads. Hence just repeatedly wait until
        // it loads. From a quick local experiment, Turnstile takes around 4 seconds to succeed
        // again after expiry
        for (let i = 0; i < TURNSTILE_MAX_TIMEOUT_SECONDS; i++) {
          await delay(1000)
          finalTurnstileToken = turnstileRef.current?.getResponse()
          if (finalTurnstileToken) break
        }
      } catch (error) {
        console.error('Turnstile error', error)
        toast({
          status: 'error',
          description: TURNSTILE_DEFAULT_ERROR_MESSAGE,
        })
        return
      }

      if (!finalTurnstileToken) {
        console.error(
          'Turnstile error: did not get token even after 3 second timeout',
        )
        toast({
          status: 'error',
          description: TURNSTILE_DEFAULT_ERROR_MESSAGE,
        })
        return
      }
    }
    const responses: FormResponseReq[] = []
    formFields.forEach((field) =>
      responses.push({
        id: field.id,
        answer: data[field.id].trim(),
      }),
    )
    try {
      const response = await submitFormMutation.mutateAsync({
        eventId: event.id,
        scheduleId,
        email: data[emailFieldId],
        slot,
        responses,
        previousBookingId: previousBooking?.id,
        turnstileToken: finalTurnstileToken,
      })
      setFormSubmitted(response)
    } catch (error) {
      if (isNestError(error) && error.status === 410) {
        setIsSlotGone(true)
      }
      if (isNestError(error) && error.status === 409) {
        // Update form fields
        await queryClient.invalidateQueries(
          publicQueryKeys.event({ eventId: event.id }),
        )
        await formMethods.trigger()
      }
      toast({
        description: getNetworkErrorMessage(error),
        status: 'error',
      })
    }
  })

  return (
    <VStack spacing={6} w="full">
      <Heading alignSelf="start" color={'blue.900'} size="sm" mb="1rem">
        ENTER DETAILS
      </Heading>
      <VStack gap="1.5rem" w="full">
        <EmailField id={emailFieldId} />
        {formFields.map((fieldData) => generateFormField(fieldData))}
      </VStack>
      {!event.shouldBypassTurnstile && <TurnstileField ref={turnstileRef} />}
      <Button
        isDisabled={isSlotGone}
        isLoading={submitFormMutation.isLoading || isSubmitting}
        type="submit"
        onClick={onSubmit}
        mt="1.5rem"
        w="full"
        h={{ base: '56px', md: 'auto' }}
      >
        Submit
      </Button>
      <FormControl isInvalid={isSlotGone}>
        <FormErrorMessage>
          The time slot you have selected is no longer available. Please go back
          and select a new time slot.
        </FormErrorMessage>
      </FormControl>
    </VStack>
  )
}
