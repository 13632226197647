import { Text, VStack } from '@chakra-ui/react'

import { DEFAULT_CLOSED_EVENT_MESSAGE } from '~shared/constants'

import { Linkify } from '~components/Linkify'

import {
  BookingFlowErrorLayout,
  BookingFlowErrorLayoutProps,
} from './BookingFlowErrorLayout'

export type EventClosedProps = {
  closedEventMessage?: string
} & BookingFlowErrorLayoutProps

export const EventClosed = ({
  closedEventMessage,
  ...rest
}: EventClosedProps) => {
  const message = closedEventMessage
    ? closedEventMessage
    : DEFAULT_CLOSED_EVENT_MESSAGE
  return (
    <BookingFlowErrorLayout {...rest}>
      <VStack paddingX={8}>
        <Text textStyle="h5">{'This event is closed.'}</Text>
        <Linkify>
          <Text>{message}</Text>
        </Linkify>
      </VStack>
    </BookingFlowErrorLayout>
  )
}
