import { useEffect, useMemo, useState } from 'react'
import { BiRightArrowAlt } from 'react-icons/bi'
import { Divider, Stack, StackDivider, VStack } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'
import dayjs, { Dayjs } from 'dayjs'

import { GetNextAvailabilityRes, GetPublicEventRes } from '~shared/dto'

import { useIsClientMobile } from '~hooks/useIsClientMobile'
import { getSlotsByDate } from '~utils/slotsByDate'
import { EventFull } from '~components/ErrorPages'

import { SlotDatePicker } from '~features/form/components/SlotDatePicker'
import { SlotTimePicker } from '~features/form/components/SlotTimePicker'
import {
  dateSelector,
  selectDateSelector,
  selectSlotSelector,
  setFormStateSelector,
  slotSelector,
  useFormStore,
} from '~features/form/hooks/useFormStore'
import { useGetSlots } from '~features/form/hooks/useGetSlots'
import { FormState } from '~features/form/types/formState'

export interface PickDateTimePageProps {
  event: GetPublicEventRes
  availability: GetNextAvailabilityRes
}

export const PickDateTimePage = ({
  event,
  availability,
}: PickDateTimePageProps): JSX.Element => {
  const isMobile = useIsClientMobile()

  const setFormState = useFormStore(setFormStateSelector)
  const date = useFormStore(dateSelector)
  const slot = useFormStore(slotSelector)
  const selectDate = useFormStore(selectDateSelector)
  const selectSlot = useFormStore(selectSlotSelector)

  // Internal implementation detail - filters fetched slots by date
  const [currentlyViewedDate, setCurrentlyViewedDate] = useState<Dayjs>(() => {
    const date = dayjs(availability.startAt)
    return date.isValid() ? date : dayjs() // Use current date as fallback
  })

  const { data: thisMonthData, isLoading: isLoadingThisMonth } = useGetSlots({
    scheduleId: event.schedules[0].id,
    startTime: currentlyViewedDate.startOf('month').valueOf(),
    endTime: currentlyViewedDate.endOf('month').valueOf(),
    // In case we reach this component despite availability.startAt being null,
    // which happens in rare cases in production
    enabled: availability.startAt !== null,
  })
  // Pre-fetch buffer months to reduce users experience of loading state
  const { data: lastMonthData } = useGetSlots({
    scheduleId: event.schedules[0].id,
    startTime: currentlyViewedDate
      .subtract(1, 'month')
      .startOf('month')
      .valueOf(),
    endTime: currentlyViewedDate.subtract(1, 'month').endOf('month').valueOf(),
    // In case we reach this component despite availability.startAt being null,
    // which happens in rare cases in production
    enabled: availability.startAt !== null,
  })
  const { data: nextMonthData } = useGetSlots({
    scheduleId: event.schedules[0].id,
    startTime: currentlyViewedDate.add(1, 'month').startOf('month').valueOf(),
    endTime: currentlyViewedDate.add(1, 'month').endOf('month').valueOf(),
    // In case we reach this component despite availability.startAt being null,
    // which happens in rare cases in production
    enabled: availability.startAt !== null,
  })

  // This effect advances the state to PickTime if a date is selected in
  // the form store state. It is necessary because this page handles both
  // the PickDate and PickTime states, and defaults to PickDate when the
  // component is mounted. If the user does the following sequence of steps,
  // the state becomes incorrect and will not show the selected date.
  // 1. Select a date
  // 2. Go back to the summary page
  // 3. Come back to this PickDateTimePage
  useEffect(() => {
    setFormState(date ? FormState.PickTime : FormState.PickDate)
  }, [date, setFormState])

  const slotsByDate = useMemo(() => {
    const slots = [
      ...(lastMonthData?.slots ?? []),
      ...(thisMonthData?.slots ?? []),
      ...(nextMonthData?.slots ?? []),
    ]

    return getSlotsByDate(slots)
  }, [lastMonthData, nextMonthData, thisMonthData])

  const slots = useMemo(() => {
    if (!date) return null
    return slotsByDate?.get(date.valueOf())
  }, [date, slotsByDate])

  // This effect unselects a timeslot if it is no longer available in
  // the list of slots returned by the server. This check runs when
  // data is first available from useGetSlots, whenever it is refetched
  // and whenever this component mounts.
  useEffect(() => {
    if (!slotsByDate || !slot || !date) {
      return
    }

    const slotsForSelectedDate = slotsByDate.get(date.valueOf())
    if (!slotsForSelectedDate) {
      selectSlot(null)
      return
    }

    const isSlotAvailable = slotsForSelectedDate.some(
      (timeslot) =>
        timeslot.startAt === slot.startAt && timeslot.endAt === slot.endAt,
    )
    if (!isSlotAvailable) {
      selectSlot(null)
    }
  }, [slotsByDate, selectSlot, date, slot])

  if (!availability.startAt) {
    return <EventFull closedEventMessage={event.closedEventMessage} />
  }

  return (
    <VStack w="full" spacing={0}>
      <Stack
        w="full"
        bgColor="white"
        divider={<StackDivider />}
        spacing={6}
        direction={{ base: 'column', md: 'row' }}
        position="relative"
      >
        <SlotDatePicker
          earliestAvailableDate={new Date(availability.startAt)}
          slotsByDate={slotsByDate}
          value={date}
          onChange={selectDate}
          onMonthChange={(currMonth: number) => {
            setCurrentlyViewedDate((m) => m.month(currMonth))
            // Remove selection if month changes, since these are sub-filters
            selectDate(null)
            selectSlot(null)
          }}
          onYearChange={(currYear: number) => {
            setCurrentlyViewedDate((m) => m.year(currYear))
            // Remove selection if year changes, since these are sub-filters
            selectDate(null)
            selectSlot(null)
          }}
          // Only show skeleton when this month's slots are unknown
          // i.e. quietly pre-fetch adjacent months without showing Skeleton
          isLoading={isLoadingThisMonth}
        />
        <SlotTimePicker
          currDate={date}
          slotDurationMins={event.schedules[0].slotDurationMins}
          slots={slots ?? []}
          onChange={selectSlot}
          isScrollable={!isMobile}
          defaultValue={slot}
        />
      </Stack>
      {(!isMobile || slot) && (
        <VStack
          w={{ base: '100dvw', md: 'full' }}
          spacing={6}
          alignItems="center"
          position={{ base: 'sticky', md: 'relative' }}
          bottom={{ base: 0, md: 'auto' }}
          zIndex="docked"
          bg="utility.ui"
          px={{ base: 6, md: 0 }}
          pt={6}
          pb={{ base: 6, md: 3 }}
        >
          <Divider />
          <Button
            rightIcon={<BiRightArrowAlt fontSize="1.5rem" />}
            w="full"
            onClick={() => setFormState(FormState.FormFields)}
            h={{ base: '56px', md: 'auto' }}
            isDisabled={!slot}
          >
            Next step
          </Button>
        </VStack>
      )}
    </VStack>
  )
}
