import { Text, VStack } from '@chakra-ui/react'

import { DEFAULT_CLOSED_EVENT_MESSAGE } from '~shared/constants'

import { Linkify } from '~components/Linkify'

import {
  BookingFlowErrorLayout,
  BookingFlowErrorLayoutProps,
} from './BookingFlowErrorLayout'

export type EventFullProps = {
  closedEventMessage?: string
} & BookingFlowErrorLayoutProps

export const EventFull = ({ closedEventMessage, ...rest }: EventFullProps) => {
  const message = closedEventMessage
    ? closedEventMessage
    : DEFAULT_CLOSED_EVENT_MESSAGE
  return (
    <BookingFlowErrorLayout {...rest}>
      <VStack paddingX={8}>
        <Text textStyle="h5">
          {'This event currently has no slots available.'}
        </Text>
        <Linkify>
          <Text>{message}</Text>
        </Linkify>
      </VStack>
    </BookingFlowErrorLayout>
  )
}
